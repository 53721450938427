.project_img{
    justify-content: center;
    /* align-items: center; */
    /* display: flex; */
    width: 300px;
    height: 500px;
    /* margin-left: 300px; */
    
}
.mixed_media_on_paper{
    justify-content: center;
    /* align-items: center; */
    /* display: flex; */
    width: 200px;
    height: 200px;
    /* margin-left: 300px; */
}

.project-container {

    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: center;

  }
