.navbar {
  width: 300px;
  height: 100vh;
  /* background-color: #f8f9fa; */
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 30px;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  margin: 1rem 0;
}

.navbar a {
  text-decoration: none;
  color: #333;
  display: flex; /* Added to align items horizontally */
  align-items: center; /* Centers items vertically */
}

.navbar a:hover {
  color: #007bff;
}

.layout {
  display: flex;
}

.content {
  margin-left: 340px;
}

.highlighted-text {
  font-weight: bold;
  /* text-decoration: underline; */
  margin-left: 10px; /* Adds space between the logo and the text */
  
}

.nav-image {
  height: 70px;
}

.dropdown-menu{
  margin-left: 15px;
}