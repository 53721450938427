
.project_img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px; /* Adds some space between images */
    margin-left:100px;
  }
  
  .project_img img {
    max-width: 100%;
    height: auto;
    object-fit: cover; /* Ensures images cover the area without distortion */
    margin-bottom: 20px; /* Adjust the spacing as needed */
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .title {
    margin-top: 40px;
    margin-left: 20px;
    
  }
  .paragraph{
    margin-left: 20px;
    margin-right: 200px;
    margin-bottom: 100px;
  }
  .titleAmani{
    margin-top : 30px
  }

  .project-detail-img {
     max-width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 16px; /* Adjust the spacing as needed */
  }