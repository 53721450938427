/* .about-container {
    
    justify-content: center;
    align-items: center;
  } */
  
  .about-content {
    text-align: center;

    margin-left: 10px;
  }
  
  .spacing{
    margin-left: 30px;;
  }
  .about-content-img {

    width: 200px;
    height: 200px;
    object-fit: cover;
    text-align: center;

  }

  .container{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    margin-left: 500px;
    margin-bottom: 40px;
    padding: 0;
    
  }