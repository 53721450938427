/* Home.css */
.home-container {
  width: 50%;
  height: 90vh;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 30%;
}

.home-content {
  text-align: center;
  margin-bottom: 20px;
  width: 90%;
  height: 30%;
}

.carousel .slide img {
  max-height: 80vh; /* Adjust to ensure the images fit within the viewport */
  max-width: 30%;
}

.carousel .thumbs-wrapper {
  display: none; /* Hide thumbnail navigation */
}

.carousel .control-dots .dot {
  background: #040000; /* Change this to your desired color */
}

.carousel .control-dots .dot:hover, 
.carousel .control-dots .dot:focus, 
.carousel .control-dots .dot.selected {
  background: #040000; /* Change this to your desired hover/selected color */
}

.home-image {
  max-height: 100vh; /* Adjust to ensure the images fit within the viewport */
  max-width: 100%;
  cursor: pointer; /* Indicate the image is clickable */
}

/* Positioning the next and previous buttons */
.carousel .control-prev.control-arrow, 
.carousel .control-next.control-arrow {
  top: 100%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  border: none;
}

.carousel .control-prev.control-arrow {
  left: 10px;
}

.carousel .control-next.control-arrow {
  right: 10px;
}

.carousel .control-prev.control-arrow:before, 
.carousel .control-next.control-arrow:before {
  font-size: 20px;
}

